<template>
  <el-dialog
    :title="title"
    :visible="visible"
    width="30%"
    :before-close="handleCancel"
  >
    <el-form
      :model="form"
      :rules="rules"
      ref="modalFrom"
      label-width="100px"
      size="small"
    >
      <el-form-item prop="postId" v-show="false">
        <el-input disable v-model="form.postId"></el-input>
      </el-form-item>
      <el-form-item label="岗位名称：" prop="postName">
        <el-input v-model="form.postName" />
      </el-form-item>
      <el-form-item label="岗位类型：" class="form-item" prop="postType">
        <el-select
          placeholder="请选择"

          class="form-item-select"
          v-model="form.postType"
        >
          <el-option
            :label="item.dictionaryName"
            :value="item.dictionaryValue"
            v-for="item in positionList"
            :key="item.dictionaryValue"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="角色权限：" class="form-item" prop="roleId">
        <el-select
          placeholder="请选择"
          filterable
          class="form-item-select"
          v-model="form.roleId"
        >
          <el-option
            :label="role.roleName"
            v-for="role in roleList"
            :key="role.roleId"
            :value="role.roleId"
          ></el-option>
        </el-select>
      </el-form-item>
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button @click="handleCancel">取 消</el-button>
      <el-button type="primary" @click="handleOk" :loading="confirmLoading">确 定</el-button>
    </span>
  </el-dialog>
</template>

<script>
import omit from 'omit.js';
import TableModalMinix from '@/minixs/TableModalMinix';
import { getRoles } from '@/api/role';
import { getDireactionListByKey } from '@/api/dictionary';

export default {
  name: 'EditPositionModal',
  mixins: [TableModalMinix],
  data () {
    return {
      title: '',
      visible: false,
      isCheckRepeat: false,
      defaultForm: {
        postId: ' ',
        postType: '',
        postName: '',
        roleId: ''
      },
      form: {
        postId: ' ',
        postType: '',
        postName: '',
        roleId: ''
      },
      url: {
        add: 'user-web-api/admin/post/addPost',
        update: 'user-web-api/admin/post/updatePost',
        checkRepeat: 'user-web-api/admin/rank/checkName'
      },
      rules: {
        postName: [
          { required: true, message: '请输入岗位名称', trigger: 'blur' },
          { min: 2, max: 20, message: '长度在 2 到 20 个字符', trigger: 'blur' }
        ],
        // postType: [{ required: true, message: '请输入岗位类型', trigger: 'blur' }],
        roleId: [{ required: true, message: '请选择角色', trigger: 'blur' }]
      },
      roleList: [],
      positionList: []
    };
  },
  created () {
    this.getRoleList();
    this.getPositionList();
  },
  methods: {
    async getPositionList () {
      try {
        const parqams = {
          data: {
            dictionaryKey: 'post_type'
          }
        };
        const res = await getDireactionListByKey(parqams);
        this.positionList = res;
      } catch (e) {
        console.log(e);
      }
    },
    add () {
      this.visible = true;
    },
    edit (row) {
      this.form.roleId = row.roleId;
      this.form.postId = row.postId;
      this.form.postName = row.postName;
      this.form.postType = row.postType ? row.postType.toString() : '';
      this.visible = true;
    },

    async handleOk () {
      await this.$refs.modalFrom.validate(async (valid) => {
        if (valid) {
          if (this.isEdit) {
            await this.handleModalUpdate({ data: { ...this.form } });
          } else {
            const data = omit(this.form, ['postId']);
            await this.handleModalAdd({ data });
          }
          this.$emit('modalFormOk');
        }
      });
    },
    async getRoleList () {
      try {
        const res = await getRoles();
        if (res.success === false) {
          this.$message.error(res.description);
          return;
        }
        this.roleList = res;
      } catch (e) {
        console.log(e);
      }
    }
  }
};
</script>

<style scoped>
.form-item-select {
  width: 100%;
}
</style>
